/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  id: 'ID',
  login_id: 'حقل الدخول',
  name: 'الاسم',
  name_ar: 'الاسم بالعربية',
  name_en: 'الاسم بالانجليزية',
  short_name: 'الاسم المختصر',
  password: 'كلمة المرور',
  password_confirmation: 'تأكيد كلمة المرور',
  email: 'البريد الإلكتروني',
  mobile: 'رقم الجوال',
  phone: 'رقم الهاتف',
  whatsapp: 'Whatsapp',
  code: 'الرمز',
  two_factor_auth: 'التحقق بخطوتين',
  user_id: 'المستخدم',
  roles_id: 'الوظائف',
  permissions_id: 'الصلاحيات',
  from_date: 'التاريخ من',
  to_date: 'التاريخ إلى',
  description: 'الوصف',
  description_ar: 'الوصف بالعربية',
  description_en: 'الوصف بالانجليزية',
  active: 'فعال',
  date: 'التاريخ',
  token: 'الرمز',
  type: 'النوع',
  status: 'الحالة',
  push_token: 'رمز الجهاز',
  verification_code: 'رمز التحقق',
  attachments: 'المرفقات',
  attachment: 'المرفق',
  ip_address: 'Ip address',
  user_agent: 'User agent',
  send_time: 'وقت الإرسال',
  username: 'اسم المستخدم',
  locale: 'اللغة',
  app_name: 'اسم الموقع',
  app_name_ar: 'اسم البرنامج بالعربية',
  app_name_en: 'اسم البرنامج بالإنجليزية',
  notification_email: 'بريد الإشعارات',
  notification_mobile: 'جوال الإشعارات',
  web: 'رابط الموقع',
  twitter: 'Twitter',
  instagram: 'Instagram',
  snapchat: 'Snapchat',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  customer_service_email: 'بريد خدمة العملاء',
  customer_service_mobile: 'جوال خدمة العملاء',
  customer_service_phone: 'هاتف خدمة العملاء',
  google_map_url: 'رابط قوقل ماب',
  app_store_url: 'رابط التطبيق App Store',
  play_store_url: 'رابط التطبيق Play Store',
  address: 'العنوان',
  address_ar: 'العنوان بالعربية',
  address_en: 'العنوان بالإنجليزية',
  contact_us: 'تواصل معنا',
  contact_us_ar: 'تواصل معنا بالعربية',
  contact_us_en: 'تواصل معنا بالانجليزية',
  about_us: 'من نحن',
  about_us_ar: 'من نحن بالعربية',
  about_us_en: 'من نحن بالانجليزية',
  privacy_policy: 'سياسة الخصوصية',
  privacy_policy_ar: 'سياسة الخصوصية بالعربية',
  privacy_policy_en: 'سياسة الخصوصية بالانجليزية',
  terms_and_conditions: 'الشروط والأحكام',
  terms_and_conditions_ar: 'الشروط والأحكام بالعربية',
  terms_and_conditions_en: 'الشروط والأحكام بالانجليزية',
  commercial_register: 'السجل التجاري',
  commercial_name: 'الاسم التجاري',
  commercial_name_ar: 'الاسم التجاري بالعربية',
  commercial_name_en: 'الاسم التجاري بالإنجليزية',
  vat_number: 'الرقم الضريبي',
  notification_methods: 'طرق الإشعارات',
  avatar: 'الصورة',
  order_by: 'ترتيب العرض',
  key: 'المفتاح',
  iso: 'ISO',
  iso2: 'ISO-2',
  iso3: 'ISO-3',
  country_id: 'الدولة',
  created_at: 'تاريخ الإضافة',
  from_created_at: 'تاريخ الإضافة من',
  to_created_at: 'تاريخ الإضافة إلى',
  message: 'الرسالة',
  city_id: 'المدينة',
  role_id: 'مجموعة المستخدم',
  price: 'السعر',
  notes: 'ملاحظات',
  icon: 'الأيقونة',
  latitude: 'الموقع على الخريطة',
  longitude: 'الموقع على الخريطة',
  subject: 'العنوان',
  image: 'الصورة',
  images: 'الصور',
  choose_image: 'اختر صورة',
  update_image: 'تعديل الصورة',
  blobAvatar: 'الصورة',
  url: 'الرابط',
  amount: 'القيمة',
  discount: 'الخصم',
  discount_type: 'نوع الخصم',
  discount_amount: 'قيمة الخصم',
  meta: 'بيانات',
  quantity: 'الكمية',
  location: 'الموقع',
  tax: 'الضريبة',
  payable_id: 'رقم المرجع',
  payable_type: 'نوع المرجع',
  total: 'الإجمالي',
  data: 'البيانات',
  from_discount_amount: 'قيمة الخصم من',
  to_discount_amount: 'قيمة الخصم إلى',
  expire_date: 'تاريخ الانتهاء',
  from_expire_date: 'تاريخ الانتهاء من',
  to_expire_date: 'تاريخ الانتهاء الى',
  read: 'تم القراءة',
  ref_key: 'الرقم المرجعي',
  attachment_type: 'نوع المرفق',
  percentage: 'النسبة',
  vat: 'الضريبة',
  vat_amount: 'قيمة الضريبة',
  payment_method_id: 'طريقة الدفع',
  gender_id: 'الجنس',
  nationality_id: 'الجنسية',
  payment_date: 'تاريخ الدفعة',
  payment_type: 'نوع الدفعة',
  paid_amount: 'المبلغ المدفوع',
  color: 'اللون',
  symbol: 'الرمز',
  contact: 'التواصل',
  start_date: 'تاريخ البداية',
  from_start_date: 'تاريخ البداية من',
  to_start_date: 'تاريخ البداية إلى',
  end_date: 'تاريخ النهاية',
  from_end_date: 'تاريخ النهاية من',
  to_end_date: 'تاريخ النهاية إلى',
  start_time: 'وقت البداية',
  from_start_time: 'وقت البداية من',
  to_start_time: 'وقت البداية إلى',
  end_time: 'وقت النهاية',
  from_end_time: 'وقت النهاية من',
  to_end_time: 'وقت النهاية إلى',
  currency_id: 'العملة',
  title_id: 'اللقب',
  bank_id: 'البنك',
  database: 'البرنامج',
  mail: 'البريد الإلكتروني',
  sms: 'الرسائل النصية',
  rate: 'المعدل',
  pay_date: 'تاريخ الدفع',
  from_pay_date: 'تاريخ الدفع من',
  to_pay_date: 'تاريخ الدفع إلى',
  payment_id: 'رقم الدفعة',
  invoice_id: 'رقم الفاتورة',
  due_amount: 'المبلغ المستحق',
  expire_days: 'صلاحية عرض السعر',
  quotation_id: 'عرض السعر',
  quotation_item_id: 'صنف عرض السعر',
  supplying_duration_id: 'مدة التوريد',
  brand_id: 'الماركة',
  quotation_terms: 'بنود عرض السعر',
  quotation_terms_ar: 'بنود عرض السعر بالعربية',
  quotation_terms_en: 'بنود عرض السعر بالإنجليزية',
  organization_name: 'اسم الجهة',
  customer_name: 'اسم العميل',
  specifications: 'الصنف والمواصفات',
  specifications_ar: 'المواصفات بالعربية',
  specifications_en: 'المواصفات بالإنجليزية',
  exterior_color: 'اللون الخارجي',
  exterior_color_id: 'اللون الخارجي',
  interior_color: 'اللون الداخلي',
  interior_color_id: 'اللون الداخلي',
  model: 'الموديل',
  plates: 'اللوحات والتعقيب',
  insurance: 'التأمين',
  quotation_items: 'أصناف عرض السعر',
  brand_item_id: 'الصنف',
  color_id: 'اللون',
  job_title_id: 'المسمى الوظيفي',
  traffics_licence: 'ترخيص مرور',
  quotation_term_id: 'بند عرض السعر',
  branch_id: 'الفرع',
  signature: 'التوقيع',
  vin: 'رقم الهيكل',
  stamp: 'الختم',
  item_specifications: 'المواصفات',
  youtube_url: 'رابط يوتيوب',
  video: 'فيديو',
  iban: 'آيبان',
  bank_account_id: 'الحساب المصرفي',
  bank_accounts_id: 'الحسابات المصرفية',
  default: 'افتراضي'
}
