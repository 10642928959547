/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

import axios from 'axios'
import { StubUrl } from '@app/Stub'

const url = StubUrl('Static')
const Quotation = StubUrl(url('Quotation'))

export default {
  search: (query) => axios.post(url('Search'), { query }),
  contactUs: (form, config = {}) => axios.post(url('Contact-Us'), form, config),
  setting: () => axios.get(url('PAS')),
  quotationItemUtilities: () => axios.get(url('QuotationItemUtilities')),
  downloadImportItemsExample: `${process.env.VUE_APP_API}/${Quotation('DownloadImportItemsExample')}`
}
