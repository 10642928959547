/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  data () {
    const apiMethods = this.$api.methods[this.$options.helperApiName] || {}
    return {
      apiMethods,
      selectedItem: {},
      dialog: !1,
      loadingDatatable: !1,
      formDialogLoading: !1,
      formErrors: {}
    }
  },
  watch: {
    dialog (v) {
      // console.log('dialog', v, this.selectedItem)
      if (!v) {
        this.resetForm()
        this.clearSelectedItem()
      } else {
        this.setDefaultSelectedItem()
      }
    }
  },
  methods: {
    refreshDatatable (item = undefined, index = undefined) {
      // console.log(item, index)
      if (this.$refs.datatable) {
        if (item !== undefined && index !== undefined) {
          this.updateDatatableItem(item, index)
        } else {
          this.$refs.datatable.$emit('refresh')
        }
      }
    },

    getDatatableItems () {
      let i = []
      if (this.$refs.datatable) {
        i = this.$refs.datatable.getDatatableItems()
      }
      return i
    },
    setDatatableItems (items) {
      if (this.$refs.datatable) {
        this.$refs.datatable.setDatatableItems(items)
      }
    },
    mergeDatatableItems (items) {
      if (this.$refs.datatable) {
        this.$refs.datatable.mergeDatatableItems(items)
      }
    },
    updateDatatableItem (item, index) {
      if (this.$refs.datatable) {
        this.$refs.datatable.updateDatatableItem(item, index)
      }
    },

    updateOneItemData (id, data) {
      if (!this.apiMethods) return undefined

      this.$root.RootShowAxiosErrorMessage = !1
      return this.apiMethods.update(id, data, { params: { singleItem: 1 } })
    },
    updateSelectedItem (data) {
      if (data) {
        this.selectedItem = {
          ...this.selectedItem,
          ...data
        }
      }
    },

    showUpdateDialog (item = {}) {
      // this.showDatatableDialog();
      this.$nextTick(() => this.fetchItemData(item.id))
    },

    setSelectedItem (item) {
      this.selectedItem = item
    },

    getShowMethod (...args) {
      return this.apiMethods.show(...args)
    },

    async fetchItemData (id) {
      if (!this.getShowMethod) return undefined

      if (this.loadingDatatable) return
      this.loadingDatatable = !0
      try {
        const { _data } = await this.getShowMethod(id)
        this.$nextTick(() => this.showDatatableDialog())
        this.$nextTick(() => {
          if (_data) {
            setTimeout(() => {
              this.setSelectedItem(_data)
            }, 90)
          }
        })
      } catch (e) {

      } finally {
        this.loadingDatatable = !1
      }
    },

    deleteItem (item) {
      if (!this.apiMethods) return undefined

      this.confirmMessage(this.$t('messages.confirmDelete'), () => {
        this.loadingDatatable = !0
        this.apiMethods.destroy(item.id).then(({ data }) => {
          data && this.alertSuccess(data.message)
          data && data.success && this.refreshDatatable()
        }).catch(({ response }) => {
          response && response.data && this.alertError(response.data.message)
        }).finally(() => (this.loadingDatatable = !1))
      })
    },

    showDatatableDialog () {
      this.dialog = !0
      this.resetForm()
      this.clearSelectedItem()
    },

    closeDatatableDialog () {
      // console.log(1);
      this.dialog = !1
      this.resetForm()
      this.clearSelectedItem()
    },

    clearSelectedItem () {
      this.selectedItem = { ...this.defaultSelectedItem }
      this.formDialogLoading = !1
    },

    setDefaultSelectedItem () {
      this.selectedItem = { ...this.defaultSelectedItem, ...this.selectedItem }
    },

    setErrors (errors = {}) {
      errors = this.$helpers.isOnlyObject(errors) ? errors : {}
      this.getForm() && this.getForm().setErrors(errors)
      this.formErrors = errors
    },

    getForm () {
      return this.$refs.form
    },

    resetForm () {
      this.getForm() && this.getForm().reset()
      this.setErrors({})
    },

    editMode () {
      return Boolean(this.selectedItem?.id)
    },
    transformFormDatatable (form) {
      return form
    },
    afterSubmit () {
      return !0
    },
    submitForm () {
      if (!this.apiMethods.update || !this.apiMethods.store) return undefined

      if (this.formDialogLoading) return
      this.formDialogLoading = !0
      const form = this.transformFormDatatable(this.selectedItem)
      for (const key of this.ignoreKeys) {
        delete form[key]
      }
      const item = {}
      Object.keys(form).forEach(e => {
        if (e.slice(-10) !== '_to_string') {
          item[e] = form[e]
        }
      })
      const action = this.editMode()
        ? this.apiMethods.update(this.getItemId, item)
        : this.apiMethods.store(item)

      this.filterSelectedItem()

      action.then(res => {
        const { data, _success, _message } = res
        if (_success) {
          _message && this.alertSuccess(_message)
          const hasCallback = typeof this.afterSubmit === 'function'
          // console.log(hasCallback)
          if (hasCallback) {
            const result = this.afterSubmit({ data, item })
            if (result !== !1) {
              this.$nextTick(() => this.closeDatatableDialog())
            }
            if (!result) {
              return res
            }
          } else this.closeDatatableDialog()

          this.$nextTick(() => this.refreshDatatable())
        }
        return res
      }).catch(e => {
        const { response } = e
        this.resetForm()
        const { data } = response || {}
        data && data.message && this.alertError(data.message)
        this.setErrors(data.errors || {})
      }).finally(() => (this.formDialogLoading = !1))
    },

    submitClickForm () {
      // console.log(this.$refs.form.$refs)
      // console.log(this.$refs.datatable)
      if (this.$refs.submit) {
        this.$refs.submit.click()
      } else if (this.$refs.form.$refs.vSubmit) {
        const el = this.$refs.form.$refs.vSubmit
        // console.log(el)
        el.disabled = !1
        el.click()
        el.disabled = !0
      }
      // else if (this.$refs.form) {
      // this.$refs.form.submit()
      // this.$refs.form.submitForm()
      // this.$refs.form.$refs.vForm.$emit('submit')
      // this.$refs.form.$refs.vForm.$el.submit()
      // }
    },

    filterSelectedItem () {
      Object.keys(this.selectedItem).forEach(k => {
        this.selectedItem[k] === undefined && (this.selectedItem[k] = null)
      })
    }

  },
  computed: {

    datatableDialogTitle () {
      return this.isReadOnly ? 'show' : (this.isNewItem ? 'store' : 'update')
    },

    getItemId () {
      return this.selectedItem?.id
    },

    isNewItem () {
      return !this.selectedItem?.id
    },

    isOldItem () {
      return !this.isNewItem
    },

    defaultSelectedItem () {
      return {}
    },

    tableUrl () {
      if (!this.apiMethods.index) return undefined
      return this.$helpers.isFunction(this.apiMethods.index) ? this.apiMethods.index(!0) : this.apiMethods.index
    },

    isReadOnly () {
      return !1
    },

    ignoreKeys () {
      return []
    }
  }
}
