/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  MediaFiles: 'المرفق|المرفقات',
  Attachments: 'المرفق|المرفقات',
  Permissions: 'الصلاحية|الصلاحيات',
  Roles: 'الوظيفة|الوظائف',
  Users: 'المستخدم|المستخدمون',
  Utilities: 'أداة|أدوات',
  Countries: 'الدولة|الدول',
  Cities: 'المدينة|المدن',
  Notifications: 'الإشعار|الإشعارات',
  Settings: 'الإعداد|الإعدادات',
  PaymentMethods: 'طريقة الدفع|طرق الدفع',
  Payments: 'الدفعة|الدفعات',
  Genders: 'الجنس|الأجناس',
  Nationalities: 'الجنسية|الجنسيات',
  Titles: 'اللقب|الألقاب',
  Currencies: 'العملة|العملات',
  Quotations: 'عرض السعر|عروض الأسعار',
  QuotationItems: 'صنف عرض السعر|أصناف عروض الأسعار',
  SupplyingDurations: 'مدة التوريد|مدد التوريد',
  Brands: 'الماركة|الماركات',
  BrandItems: 'الصنف|الأصناف',
  Colors: 'اللون|الألوان',
  JobTitles: 'المسمى الوظيفي|المسميات الوظيفية',
  QuotationTerms: 'بند عرض السعر|بنود عروض الأسعار',
  Branches: 'الفرع|الفروع',
  Specifications: 'الصفة|المواصفات',
  BankAccounts: 'الحساب المصرفي|الحسابات المصرفية'
}
