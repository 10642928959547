/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export const APP_ROUTES = {
  notFound: 'not-found',
  homePage: 'home-page',
  auth: {
    login: 'auth.login',
    register: 'auth.register',
    forgotPassword: 'auth.forgotPassword',
    logout: 'logout'
  },
  public: {
    aboutUs: 'aboutUs',
    contactUs: 'contactUs',
    privacyPolicy: 'privacyPolicy',
    termsAndConditions: 'termsAndConditions',
    viewSpecification: 'view-specification'
  },
  user: {
    home: 'panel.user.home',
    profile: 'panel.user.profile',
    index: 'panel.user.index',
    notifications: 'panel.notification.index'
  },
  quotation: {
    index: 'panel.quotation.index'
  },
  specifications: {
    index: 'panel.utilities.specifications'
  }
}
