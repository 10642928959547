/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

const global = {
  primary: '#ec642b',
  accent: '#00a6a0',
  anchor: '#5d5c61',
  gold: '#C7A359',
  orange: '#f7941e',
  blue: '#1C75BC',
  pink: '#EC008C',
  'dark-blue': '#00838f',
  'light-blue': '#4cbac3',
  white: '#fafafa'
}

export const theme = {
  storageKey: 'theme_dark_storage',
  rtl: !0,
  dark: !1,
  themes: {
    light: {
      ...global,
      bar: global.primary,
      toolbar: global.primary,
      footer: global.anchor
    },
    dark: {
      ...global,
      bar: global.primary,
      toolbar: global.primary,
      footer: global.primary
    }
  }
}

export const locale = {
  defaultLocale: 'ar',
  storageKey: 'locale_storage'
}

export const GOOGLE = {
  mapKey: '',
  defaultConfig: {
    zoom: 17,
    gestureHandling: 'cooperative'
  },
  libraries: [
    'visualization',
    'places'
  ].join(','),
  reCaptchaKey: ''
}

export const currency = {
  value: {
    code: 'SAR',
    short_name: 'ريال',
    balance: 1.0
  },
  storageKey: 'currency_storage'
}

export default { theme, locale, currency }
