/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2016-2022 All rights reserved.
 * MyTh Ahmed Fayez
 */

export default {
  MediaFiles: 'Attachment|Attachments',
  Attachments: 'Attachment|Attachments',
  Permissions: 'Permission|Permissions',
  Roles: 'Role|Roles',
  Users: 'User|Users',
  Utilities: 'Utility|Utilities',
  Countries: 'Country|Countries',
  Cities: 'City|Cities',
  Notifications: 'Notification|Notifications',
  Settings: 'Setting|Settings',
  PaymentMethods: 'Payment Method|Payment Methods',
  Payments: 'Payment|Payments',
  Genders: 'Gender|Genders',
  Nationalities: 'Nationality|Nationalities',
  Titles: 'Title|Titles',
  Currencies: 'Currency|Currencies',
  Quotations: 'Quotation|Quotations',
  QuotationItems: 'Quotation Item|Quotation Items',
  SupplyingDurations: 'Supplying Duration|Supplying Durations',
  Brands: 'Brand|Brands',
  BrandItems: 'Item|Items',
  Colors: 'Color|Colors',
  JobTitles: 'Job Title|Job Titles',
  QuotationTerms: 'Quotation Term|Quotation Terms',
  Branches: 'Branch|Branches',
  Specifications: 'Specification|Specifications',
  BankAccounts: 'Bank Account|Bank Accounts'
}
